// $(function () {
//     var lastMark = 0;
//     $('.markBg, .markBg-m, .courseOverView__box').each(function () {
//         let $markList = $('.markBg').find('a'),
//             $markList_m = $('.markBg-m').find('li'),
//             $markCard = $('.courseOverView__box').find('.courseOverView__card .courseOverView__card__title');
//
//         $markList.each(function (currentMark,obj) {
//             $(obj).on('click', function (e) {
//                 e.preventDefault();
//                 $markCard.eq(lastMark).css({backgroundColor: '#0075a9'});
//                 $markCard.eq(currentMark).css({backgroundColor: 'red'});
//                 lastMark = currentMark;
//
//             });
//         });
//         $markList_m.each(function (currentMark,obj) {
//             $(obj).on('click', function (e) {
//                 e.preventDefault();
//                 $markCard.eq(lastMark).css({backgroundColor: '#0075a9'});
//                 $markCard.eq(currentMark).css({backgroundColor: 'red'});
//                 lastMark = currentMark;
//
//             });
//         });
//
//
//         $markList.eq(0).trigger('click');
//     })
// });