$(function () {
    var lastnewCourseTab = 0;
   $('.newCourses').each(function () {
       let $tabList = $(this).find('.newCourses__nav ul'),
           $tabItems = $tabList.find('li'),
           $tabPanels = $(this).find('.newCourses__box--nav .newCourses__item');

       $tabItems.each(function (newCourseCurrentTab,obj) {
           $(obj).on('click', function (e) {
               e.preventDefault();
               $tabPanels.eq(lastnewCourseTab).css({display: 'none'});
               $tabPanels.eq(newCourseCurrentTab).css({display: 'block'});
               lastnewCourseTab = newCourseCurrentTab;
               // console.log('lastTab:'+lastTab+' ,topCourseCurrentTab:'+topCourseCurrentTab+' ,obj:'+$(obj).attr('href'));

               // Add active class to the tab which was clicked!!
               let $this = $(obj);
               // console.log($this.attr('href'));
               if($this.hasClass('active')) {
                   return;
               }

               $tabItems.removeClass('active');
               $this.addClass('active');

               // $topCoursePanels.css('display', 'none');
               // $($this.attr('href')).css('display', 'block');
           });
       });


       $tabItems.eq(0).trigger('click');
   })
});